<style lang="scss">
  .error-border {
    border-color: #ff0000 !important;
  }

  .tab-button {
    &.active{
      font-weight:bold;
    }
  }

  .vc-chrome-toggle-btn {
    display: none !important;
  }

  .separate-section{
    border: 1px solid #ededed;
    border-width: 1px;
    border-radius: 4px;
    background:#fff;
  }
</style>
<template>
  <div
    id="outer-wrapper"
    @click="onClickBody"
  >
    <vue-headful
      :title="`${metaTitle} | Octoa`"
    />
    <v-inner-header
      :is-saving.sync="isSaving"
      :is-saved.sync="isSaved"
      :user-might-update.sync="userMightUpdate"
      redirect="/templates"
      @click="alertExit"
    />

    <v-video-modal
      name="lead"
      title="Lead form creation"
      description="A video to help you get started."
      step="leadform-edit"
      thumbnail="lead-form.png"
      embed="2ItCnf1rz1E"
      :show.sync="isShowVideoModal"
      @close="closeVideoModal"
    />

    <div class="max-w-lg mx-auto px-4">
      <div class="flex -mx-2 lead-form-edit-container mb-10">
        <div
          v-if="loading"
          class="w-full px-2 text-center"
        >
          <img
            src="@/assets/img/icons/loader.svg"
            width="50"
            class="mt-32 text-center"
          >
        </div>
        <div
          v-else
          id="lead-form-setting"
          class="w-full px-2 mt-16"
        >
          <div class="bg-white shadow rounded px-8 py-8">
            <div class="flex items-center justify-between mb-2">
              <div class="w-1/2 md:w-2/3">
                <h1 class="text-xl">
                  Lead Form
                </h1>
              </div>
              <div class="w-1/2 md:w-1/3 text-right">
                <div class="inline-block">
                  <v-tutorial-button @click="showVideoModal" />
                </div>
              </div>
            </div>
            <p class="text-sm mb-3 text-grey-dark">
              Give it a name and message after someone submitted the form itself.
            </p>


            <input
              v-model="formSetting.name"
              v-validate="{ required: true }"
              type="text"
              class="w-full"
              name="name"
              placeholder="Enter a name for your form"
            >
            <span class="error inline-block mt-2">{{ errors.first('name') }}</span>
          </div>
          <div id="lead-form">
            <div class="lead-form-edit">
              <div class="flex heading text-center">
                <div
                  class="w-1/3 tab-button"
                  :class="{'active' : view == 'create'}"
                  @click="changeView('create')"
                >
                  Create
                </div>
                <div
                  class="w-1/3 tab-button"
                  :class="{'active' : view == 'preview'}"
                  @click="changeView('preview')"
                >
                  Design & Preview
                </div>
                <div
                  class="w-1/3 tab-button"
                  :class="{'active' : view == 'embed'}"
                  @click="changeView('embed')"
                >
                  Embed code
                </div>
              </div>
              <div
                v-show="view == 'create'"
                class="py-5"
              >
                <h1 class="text-xl mb-2 px-8">
                  Create Lead Form
                </h1>
                <p class="px-8 pb-5 leading-normal">
                  Create a lead form template to implement on your website. When clients fill in the lead form you will receive all the filled in information in your email and Octoa.
                </p>
                <div class="flex px-8 items-center">
                  <div class="mr-4">
                    <label>Language <v-help-tooltip
                      :options="languageContent"
                    /></label>
                  </div>
                  <div>
                    <div class="select-container w-full">
                      <select v-model="design.language">
                        <option v-for="language in activeLanguages" :value="language.code" :key="language.code">
                          {{ language.label }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="flex px-8 items-center mt-4">
                  <div class="mr-4">
                    <label>
                      Workflow <v-help-tooltip :options="{ content: 'The default workflow for the project once a potential client completed the form.' }"/>
                    </label>
                  </div>
                  <div class="flex items-center">
                    <div class="select-container mr-2">
                      <select :disabled="typeof design.enabledWorkflows !== 'undefined' && design.enabledWorkflows.length ? true : false" v-if="this.workflows.length > 0" v-model="formSetting.workflowId">
                        <option v-for="workflow in workflows" :key="workflow.id" :value="workflow.id">
                          {{ workflow.name }}
                        </option>
                      </select>
                    </div>
                    <p>Or add a workflow question to trigger the workflow (this will disable this option)</p>
                  </div>

                </div>

                <div class="px-8 mt-5 mb-3">
                  <v-checkbox
                    id="isRedirectOn"
                    v-model="design.isRedirectOn"
                    :checked="design.isRedirectOn"
                    label="Redirect to another page after submitting the form"
                  />
                </div>
                <div
                  v-show="design.isRedirectOn"
                  class="px-8"
                >
                  <input
                    v-model="design.redirectLink"
                    type="text"
                    placeholder="Enter a website URL"
                  >
                </div>
                 <div class="px-8 mt-5 mb-3">
                  <v-checkbox
                    id="isLegalCheck"
                    v-model="design.isLegalCheck"
                    :checked="design.isLegalCheck"
                    label="Include a legal box that client needs to check"
                  />
                </div>
                <div
                  v-show="design.isLegalCheck"
                  class="px-8"
                >
                  <v-editor
                    toolbar="basic"
                    :editor-content.sync="design.legalText"
                    :allow-token="false"
                  />
                </div>
              </div>

              <div
                v-show="view == 'preview'"
                class="py-5 content text-sm"
              >
                <h1 class="text-xl mb-5 px-8">
                  Design your Lead Form
                </h1>
                <div class="flex flex-wrap mx-8 pb-5 mb-5 items-center border-b border-solid border-grey-lighter">
                  <div class="flex w-full md:w-1/2 lg:w-1/3 ">
                    <div class="flex w-full justify-between items-center">
                      <div class="w-1/2">
                        Background:
                      </div>
                      <div class="w-1/2">
                        <div ref="bgColorPicker">
                          <input
                            v-model="design.backgroundColor.hex"
                            type="text"
                            class="w-24 ml-2 inline-block"
                            @focus="showColorPicker('bgColorPicker')"
                          >
                          <color-picker
                            v-show="displayColorPicker == 'bgColorPicker'"
                            v-model="design.backgroundColor"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-5 lg:mt-0 flex w-full lg:w-1/3 ">
                    <v-checkbox
                      id="isBackgroundTransparent"
                      v-model="design.isBackgroundTransparent"
                      :checked="design.isBackgroundTransparent"
                      label="Make background transparent"
                    />
                  </div>
                </div>

                <div class="flex flex-wrap px-8 pb-3 mb-3">
                  <div class="flex w-full md:w-1/2 lg:w-1/3">
                    <div class="flex flex-wrap w-full justify-between items-center">
                      <div class="w-1/2">
                        Label text:
                      </div>
                      <div class="w-1/2">
                        <div ref="labelText">
                          <input
                            v-model="design.labelText.hex"
                            type="text"
                            class="w-24 ml-2 inline-block"
                            @focus="showColorPicker('labelText')"
                          >
                          <color-picker
                            v-show="displayColorPicker == 'labelText'"
                            v-model="design.labelText"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex w-full md:w-1/2 lg:w-1/3">
                    <div class="flex flex-wrap w-full mt-5 md:mt-0 justify-between items-center">
                      <div class="w-1/2">
                        Input text:
                      </div>
                      <div class="w-1/2">
                        <div ref="inputText">
                          <input
                            v-model="design.inputText.hex"
                            type="text"
                            class="w-24 ml-2 inline-block"
                            @focus="showColorPicker('inputText')"
                          >
                          <color-picker
                            v-show="displayColorPicker == 'inputText'"
                            v-model="design.inputText"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
<!--                  <div class="flex w-full md:w-1/2 mt-5 lg:mt-0 lg:w-1/3 ">-->
<!--                    <div class="flex w-full justify-between items-center">-->
<!--                      <div class="w-1/2">-->
<!--                        Required text:-->
<!--                      </div>-->
<!--                      <div class="w-1/2">-->
<!--                        <div ref="requiredText">-->
<!--                          <input-->
<!--                            v-model="design.requiredText.hex"-->
<!--                            type="text"-->
<!--                            class="w-24 ml-2 inline-block"-->
<!--                            @focus="showColorPicker('requiredText')"-->
<!--                          >-->
<!--                          <color-picker-->
<!--                            v-show="displayColorPicker == 'requiredText'"-->
<!--                            v-model="design.requiredText"-->
<!--                          />-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
                </div>

                <div class="flex flex-wrap px-8 pb-3 mb-3">
                  <div class="flex w-full md:w-1/2 lg:w-1/3">
                    <div class="flex w-full justify-between items-center">
                      <div class="w-1/2">
                        Input background:
                      </div>
                      <div class="w-1/2">
                        <div ref="inputBackground">
                          <input
                            v-model="design.inputBackground.hex"
                            type="text"
                            class="w-24 ml-2 inline-block"
                            @focus="showColorPicker('inputBackground')"
                          >
                          <color-picker
                            v-show="displayColorPicker == 'inputBackground'"
                            v-model="design.inputBackground"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex w-full md:w-1/2 lg:w-1/3">
                    <div class="flex flex-wrap w-full mt-5 md:mt-0 justify-between items-center">
                      <div class="w-1/2">
                        Border color:
                      </div>
                      <div class="w-1/2">
                        <div ref="borderColor">
                          <input
                            v-model="design.borderColor.hex"
                            type="text"
                            class="w-24 ml-2 inline-block"
                            @focus="showColorPicker('borderColor')"
                          >
                          <color-picker
                            v-show="displayColorPicker == 'borderColor'"
                            v-model="design.borderColor"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex w-full md:w-1/2 mt-5 lg:mt-0 lg:w-1/3 ">
                    <div class="flex w-full justify-between items-center">
                      <div class="w-1/2">
                        Input radius:
                      </div>
                      <div class="w-1/2">
                        <div ref="inputRadius">
                          <div class="select-container mx-2 w-24">
                            <select v-model="design.inputRadius">
                              <option value="0">
                                0px
                              </option>
                              <option value="2">
                                2px
                              </option>
                              <option value="4">
                                4px
                              </option>
                              <option value="8">
                                8px
                              </option>
                              <option value="16">
                                16px
                              </option>
                              <option value="32">
                                32px
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex flex-wrap mx-8 pb-5 pt-5 items-center border-t border-solid border-grey-lighter">
                  <div class="flex w-full md:w-1/2 lg:w-1/3">
                    <div class="flex w-full justify-between items-center">
                      <div class="w-1/2">
                        Button label:
                      </div>
                      <div class="w-1/2">
                        <div ref="buttonLabel">
                          <input
                            v-model="design.buttonLabel"
                            type="text"
                            class="w-24 ml-2 inline-block"
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex w-full md:w-1/2 lg:w-1/3">
                    <div class="flex flex-wrap w-full mt-5 md:mt-0 justify-between items-center">
                      <div class="w-1/2">
                        Button text:
                      </div>
                      <div class="w-1/2">
                        <div ref="buttonColor">
                          <input
                            v-model="design.buttonColor.hex"
                            type="text"
                            class="w-24 ml-2 inline-block"
                            @focus="showColorPicker('buttonColor')"
                          >
                          <color-picker
                            v-show="displayColorPicker == 'buttonColor'"
                            v-model="design.buttonColor"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex w-full md:w-1/2 mt-5 lg:mt-0 lg:w-1/3 ">
                    <div class="flex w-full justify-between items-center">
                      <div class="w-1/2">
                        Button background:
                      </div>
                      <div class="w-1/2">
                        <div ref="buttonBackground">
                          <input
                            v-model="design.buttonBackground.hex"
                            type="text"
                            class="w-24 ml-2 inline-block"
                            @focus="showColorPicker('buttonBackground')"
                          >
                          <color-picker
                            v-show="displayColorPicker == 'buttonBackground'"
                            v-model="design.buttonBackground"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex mx-8 mb-3 pt-5 items-center border-t border-solid border-grey-lighter">
                  <div class="flex w-full md:w-1/2 lg:w-1/3">
                    <div class="flex w-full justify-between items-center">
                      <div class="w-1/2">
                        Error text:
                      </div>
                      <div class="w-1/2">
                        <div ref="errorColor">
                          <input
                            v-model="design.errorColor.hex"
                            type="text"
                            class="w-24 ml-2 inline-block"
                            @focus="showColorPicker('errorColor')"
                          >
                          <color-picker
                            v-show="displayColorPicker == 'errorColor'"
                            v-model="design.errorColor"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex w-full md:w-1/2 lg:w-1/3">
                    <div class="flex w-full justify-between items-center">
                      <div class="w-1/2">
                        Legal box text color:
                      </div>
                      <div class="w-1/2">
                        <div ref="legalBoxColor">
                          <input
                            v-model="design.legalBoxColor.hex"
                            type="text"
                            class="w-24 ml-2 inline-block"
                            @focus="showColorPicker('legalBoxColor')"
                          >
                          <color-picker
                            v-show="displayColorPicker == 'legalBoxColor'"
                            v-model="design.legalBoxColor"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End preview view -->
              <div
                v-show="view == 'embed'"
                class="py-5 content text-sm"
              >
                <div class="px-8">
                  <h1 class="text-xl">
                    Embed Code
                  </h1>
                  <div v-if="embedLink != null">
                    <p class="pt-5">
                      Copy the code below and paste it anywhere on your site where you have access to the source code. The form will automatically fill any HTML container it is in.
                    </p>

                    <blockquote class="code">
                      <code>{{ embedLink }}</code>
                    </blockquote>

                    <div
                      v-clipboard:copy="embedLink"
                      v-clipboard:success="onCopy"
                      class="copy green-btn text-center mb-5"
                    >
                      Copy to clipboard
                    </div>
                  </div>
                  <div v-else>
                    <div class="code">
                      Before we can generate the embed code for your website, your form first needs to be saved.
                    </div>
                  </div>
                  <p>
                    Need help? Send us an email and we help you out. <a
                      class="green underline"
                      href="mailtop:hello@octoa.com"
                    >hello@octoa.com</a>
                  </p>
                </div>
              </div>
              <!-- End code view -->
            </div>

            <!-- Preview section 2 / 3 -->
            <div
              v-show="view == 'preview'"
              class="content py-8 mb-5 separate-section"
            >
              <div
                class="px-3 mx-8 rounded max-w-md mx-auto py-6"
                :style="{ backgroundColor: backgroundPreview }"
              >
                <div
                  v-for="item in listofFields"
                  :key="item.id"
                  class="fields-container "
                >
                  <div class="fields-preview mb-4 px-8 active">
                    <div class="input">
                      <div class="w-full">
                        <div class="flex justify-between items-center mb-2">
                          <label
                            class="block"
                            :style="{ color: design.labelText.hex }"
                          >
                            {{ item.label }} <span
                            v-if="item.is_required"
                            class="float-right text-grey"
                            :style="{ color: design.labelText.hex }"
                          >*</span>
                          </label>
                          <div class="flex items-center">
                            <v-checkbox
                              v-if="item.type == 'datepicker' && item.is_multi_days"
                              id="multi-days"
                              v-model="multiDay"
                              name="multi-days"
                              :checked="multiDay"
                              :label="getTranslatedLabel"
                              :text-style="{ color: design.labelText.hex }"
                            />
                          </div>
                        </div>
                      </div>
                      <div v-if="item.type == 'text'">
                        <input
                          type="text"

                          class="lead"
                          :style="{ color:design.inputText.hex, borderColor:design.borderColor.hex, backgroundColor:design.inputBackground.hex, borderRadius: design.inputRadius+'px' }"
                        >
                      </div>
                      <div v-if="item.type == 'datepicker'">
                        <div v-if="multiDay" class="flex">
                          <!-- Start date picker -->
                          <v-date-picker
                              v-model="fakeStartDate"
                              class="datepicker w-full mr-2"
                              :popover="{ visibility: 'click' }"
                              :masks="{L: 'WWW, MMM D, YYYY'}"
                              :input-props="{ style: 'border-color:'+design.borderColor.hex+'; color:'+design.inputText.hex+'; border-radius:'+design.inputRadius+'px; background-color:'+design.inputBackground.hex+'; border-radius:'+design.inputRadius+'px' }"
                          >
                            <template v-slot="{ inputValue, inputEvents }">
                              <input
                                  type="text"

                                  :style="{ color:design.inputText.hex, borderColor:design.borderColor.hex, backgroundColor:design.inputBackground.hex, borderRadius: design.inputRadius+'px' }"
                                  :value="inputValue"
                                  v-on="inputEvents"
                                  placeholder="Start date"
                              />
                            </template>
                          </v-date-picker>

                          <!-- End date picker -->
                          <v-date-picker
                              v-model="fakeEndDate"
                              class="datepicker w-full"
                              :popover="{ visibility: 'click' }"
                              :masks="{L: 'WWW, MMM D, YYYY'}"
                              :input-props="{ style: 'border-color:'+design.borderColor.hex+'; color:'+design.inputText.hex+'; border-radius:'+design.inputRadius+'px; background-color:'+design.inputBackground.hex+'; border-radius:'+design.inputRadius+'px' }"
                          >
                            <template v-slot="{ inputValue, inputEvents }">
                              <input
                                  type="text"

                                  :style="{ color:design.inputText.hex, borderColor:design.borderColor.hex, backgroundColor:design.inputBackground.hex, borderRadius: design.inputRadius+'px' }"
                                  :value="inputValue"
                                  v-on="inputEvents"
                                  placeholder="End date"
                              />
                            </template>
                          </v-date-picker>
                        </div>

                        <div v-else>
                          <!-- Single date picker -->
                          <v-date-picker
                              v-model="fakeDate"
                              class="datepicker"
                              :popover="{ visibility: 'click' }"
                              :masks="{L: 'WWW, MMM D, YYYY'}"
                              :input-props="{ style: 'border-color:'+design.borderColor.hex+'; color:'+design.inputText.hex+'; border-radius:'+design.inputRadius+'px; background-color:'+design.inputBackground.hex+'; border-radius:'+design.inputRadius+'px' }"
                          >
                            <template v-slot="{ inputValue, inputEvents }">
                              <input
                                  type="text"

                                  :style="{ color:design.inputText.hex, borderColor:design.borderColor.hex, backgroundColor:design.inputBackground.hex, borderRadius: design.inputRadius+'px' }"
                                  :value="inputValue"
                                  v-on="inputEvents"
                              />
                            </template>
                          </v-date-picker>
                        </div>
                      </div>

                      <div v-if="item.type == 'textarea'">
                        <textarea
                          class="lead"

                          :style="{ color:design.inputText.hex, borderColor:design.borderColor.hex, backgroundColor:design.inputBackground.hex, borderRadius: design.inputRadius+'px' }"
                        />
                      </div>

                      <div v-if="item.type == 'workflow'">
                        <div
                          class="select-container tall w-full"
                        >
                          <select
                            v-model="firstWorkflowId"
                            :style="{ color:design.inputText.hex, borderColor:design.borderColor.hex, backgroundColor:design.inputBackground.hex, borderRadius: design.inputRadius+'px' }"
                          >
                            <option
                              v-for="(option) in design.enabledWorkflows"
                              :value="option.id"
                            >
                              {{ option.label }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div v-if="item.type == 'dropdown'">
                        <div
                          class="select-container tall w-full"
                        >
                          <select :style="{ color:design.inputText.hex, borderColor:design.borderColor.hex, backgroundColor:design.inputBackground.hex, borderRadius: design.inputRadius+'px' }">
                            <option
                              v-for="option in item.options"
                              :value="option"
                            >
                              {{ option }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="design.isLegalCheck">
                  <div
                    class="custom-checkbox inline-block px-8 mt-2"
                  >
                    <input
                      id="legal"
                      name="legal"
                      class="styled-checkbox"
                      type="checkbox"
                    >
                    <label
                      for="legal"
                      :style="{'color': design.legalBoxColor ? design.legalBoxColor.hex : '#000' }"
                      v-html="design.legalText"
                    >
                    </label>
                  </div>
                </div>
                <div class="text-center mt-5">
                  <input
                    type="submit"
                    name="submit"
                    :value="design.buttonLabel"
                    class="btn"
                    :style="{backgroundColor: design.buttonBackground.hex, color: design.buttonColor.hex}"
                  >
                </div>
              </div>
            </div>
            <div
              v-show="view == 'preview' && !design.isRedirectOn"
              class="content py-4 pb-8 mb-8 separate-section"
            >
              <div class="px-8 my-5">
                <h1 class="text-xl mb-2">
                  Design thank you message
                </h1>
                <p>The thank you message after submitting the form will appear underneath the button.</p>
              </div>
              <div class="flex flex-wrap  mx-8 pb-5 mb-5 items-center justify-between border-b border-solid border-grey-lighter">
                <div class="flex w-full md:w-1/2 lg:w-1/3">
                  <div class="flex w-full items-center">
                    <div class="w-1/2 lg:w-1/3 ">
                      Background:
                    </div>
                    <div class="w-1/2 lg:w-2/3">
                      <div ref="thankYouBackground">
                        <input
                          v-model="design.thankYouBackground.hex"
                          type="text"
                          class="w-24 ml-2 inline-block"
                          @focus="showColorPicker('thankYouBackground')"
                        >
                        <color-picker
                          v-show="displayColorPicker == 'thankYouBackground'"
                          v-model="design.thankYouBackground"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex w-full md:w-1/2 mt-5 lg:mt-0 lg:w-1/3">
                  <v-checkbox
                    id="isThankYouBackgroundTransparent"
                    v-model="design.isThankYouBackgroundTransparent"
                    :checked="design.isThankYouBackgroundTransparent"
                    label="Make background transparent"
                  />
                </div>
                <div class="flex w-full md:w-1/2 mt-5 lg:mt-0 lg:w-1/3 ">
                  <div class="flex w-full justify-between items-center">
                    <div class="w-1/2 lg:text-right">
                      Text color:
                    </div>
                    <div class="w-1/2 ">
                      <div ref="thankYouTextColor">
                        <input
                          v-model="design.thankYouTextColor.hex"
                          type="text"
                          class="w-24 ml-2 inline-block"
                          @focus="showColorPicker('thankYouTextColor')"
                        >
                        <color-picker
                          v-show="displayColorPicker == 'thankYouTextColor'"
                          v-model="design.thankYouTextColor"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="mx-8 bg-blue-washed-light px-3 py-3 rounded"
                :style="{backgroundColor: thankYouBackgroundPreview, color: design.thankYouTextColor.hex}"
              >
                <p class="text-center">
                  {{ formSetting.successMessage }}
                </p>
              </div>
            </div>

            <div>
              <div
                v-show="view == 'create'"
                class="content text-sm"
              >
                <!-- Start Custom -->
                <div>
                  <!-- Draggable -->
                  <draggable
                    :list="listofFields"
                    handle=".drag"
                    drag-class="drag-helper"
                    animation="200"
                  >
                    <transition-group
                      type="transition"
                      name="flip-list"
                    >
                      <div
                        v-for="(item, index) in listofFields"
                        :key="index"
                        @click="toggleActive(item)"
                      >
                        <div class="draggable-item bg-white rounded">
                          <div class="fields-container">
                            <div
                              v-if="item.isActive"
                              class="fields-edit pt-3 pb-5 px-8 mb-5 "
                            >
                              <div class="flex flex-wrap border-grey-lighter border-b pb-3">
                                <div class="drag w-full">
                                  <div class="icon pt-1 pb-3 text-center">
                                    <img src="@/assets/img/icons/drag.svg">
                                  </div>
                                </div>
                                <div class="w-full">
                                  <div class="flex">
                                    <div class="w-full md:w-1/2">
                                      <div class="input-group mb-2">
                                        <label class="inline-block w-24"> Field label</label>
                                        <input
                                          v-model="item.label"
                                          type="text"
                                          class="w-full max-w-xs mb"
                                          placeholder="Label (ex.Your email address)"
                                        >
                                      </div>
                                      <div class="pb-6 pt-4" v-if="item.type == 'datepicker'">
                                        <div
                                          class="custom-checkbox inline-block"
                                          :class="[type == 'dark' ? 'dark' : '', type == 'tab' ? 'tab' : '']"
                                          style="width: auto!important"
                                        >
                                          <input
                                            id="is_multi_day_label"
                                            name="is_multi_days"
                                            class="styled-checkbox"
                                            v-model="item.is_multi_days"
                                            type="checkbox"
                                            @change="$emit('input', $event.target.checked)"
                                          >
                                          <label
                                            for="is_multi_day_label"
                                          >
                                            Multi-day (shows date range)
                                          </label>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="w-1/2 text-right">
                                      <span
                                        v-if="item.type == 'text'"
                                        class="field-type-label"
                                      >Short answer</span>
                                      <span
                                        v-if="item.type == 'textarea'"
                                        class="field-type-label"
                                      >Long answer</span>
                                      <span
                                        v-if="item.type == 'datepicker'"
                                        class="field-type-label"
                                      >Date</span>
                                      <span
                                        v-if="item.type == 'dropdown'"
                                        class="field-type-label"
                                      >Dropdown</span>
                                      <span
                                        v-if="item.type == 'workflow'"
                                        class="field-type-label"
                                      >Workflow</span>
                                    </div>
                                  </div>
                                  <div
                                    v-if="item.type == 'dropdown'"
                                    class="input-group mb-2"
                                  >
                                    <label class="inline-block w-24">Items</label>

                                    <div v-for="(option, optionIndex) in item.options">
                                      <div class="flex items-center">
                                        <div class="mb-2 w-full md:w-4/12">
                                          <input
                                            v-model="item.options[optionIndex]"
                                            type="text"
                                            class="w-full max-w-xs mb"
                                            placeholder="Options"
                                          >
                                        </div>
                                        <div class="ml-2">
                                          <img
                                            src="@/assets/img/icons/ico-delete-green.svg"
                                            width="15"
                                            class="mb-1"
                                            @click="removeDropdownOption(index, optionIndex)"
                                          >
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="text-green underline mt-1"
                                      @click="addDropdownOption(index)"
                                    >
                                      + Add option
                                    </div>
                                  </div>
                                  <div
                                    v-if="!item.is_mapping_fix && item.type != 'dropdown' && item.type != 'workflow'"
                                    class="input-group mb-2"
                                  >
                                    <label class="inline-block">
                                      Save data to <v-help-tooltip
                                        :options="tooltipContent"
                                      />
                                    </label>
                                    <div
                                      v-if="!item.is_mapping_fix"
                                      class="select-container tall "
                                    >
                                      <select
                                        v-model="item.mapping"
                                        class="w-32"
                                        @change="changeMapping(item.id)"
                                      >
                                        <option value="nothing">
                                          Nothing
                                        </option>
                                        <option
                                          v-for="map in mapping_options"
                                          v-if="(map.value == 'date' && item.type =='datepicker') && (map.assignedTo == item.id || map.assignedTo == null)"
                                          :key="map.id"
                                          :value="map.value"
                                        >
                                          {{ map.label }}
                                        </option>
                                        <option
                                          v-for="map in mapping_options"
                                          v-if="(map.value != 'date' && item.type !='datepicker') && (map.assignedTo == item.id || map.assignedTo == null)"
                                          :key="map.id"
                                          :value="map.value"
                                        >
                                          {{ map.label }}
                                        </option>
                                      </select>
                                    </div>
                                    <div v-else>
                                      <div class="select-container tall">
                                        <select
                                          v-model="item.mapping"
                                          class="capitalize w-32"
                                        >
                                          <option :value="item.mapping">
                                            {{ item.mapping }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    v-if="item.type == 'workflow'"
                                    class="input-group mb-2"
                                  >
                                    <label class="inline-block w-full">Select the workflow you want people to be able to choose:</label>
                                    <div
                                      v-for="(workflow, workflowIndex) in workflows"
                                      :key="workflow.id"
                                      class="flex items-center"
                                    >
                                      <div class="mb-2 w-full md:w-4/12">
                                        <v-checkbox
                                          :id="`workflow${workflow.id}`"
                                          :value="workflow.id"
                                          :label="workflow.name"
                                          :checked="design.enabledWorkflows.find(w => w.id === workflow.id) ? true : false"
                                          @click="toggleWorkflow(workflow.id, $event.target.checked)"

                                        />
                                        <input
                                          v-if="design.enabledWorkflows.find(w => w.id === workflow.id)"
                                          v-model="design.enabledWorkflows.find(w => w.id === workflow.id).label"
                                          type="text"
                                          class="w-full max-w-xs mb"
                                          placeholder="Workflow name"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="flex flex-row-reverse text-right">
                                <div class="pt-3">
                                  <a
                                    v-if="!item.is_mapping_fix"
                                    class="underline blue mr-2 pr-2 pt-3 pb-2 border-r border-grey-lighter"
                                    @click="removeAdditionalFields(index)"
                                  >Delete</a>
                                  <v-checkbox
                                    v-if="!item.is_mapping_fix"
                                    :id="`listofFields${index}`"
                                    v-model="item.is_required"
                                    :active="(item.is_mapping_fix) ? 'disabled' : ''"
                                    label="Required"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              v-else
                              class="fields-preview mb-5 py-5 px-8 active"
                            >
                              <div class="input">
                                <div class="flex w-full">
                                  <div class="w-1/2">
                                    <div class="mb-3">
                                      {{ item.label }}
                                    </div>
                                  </div>
                                  <div class="w-1/2 text-right">
                                    <span
                                      v-if="item.is_required"
                                      class="text-grey-semi-light"
                                    >Required</span>
                                  </div>
                                </div>
                                <div v-if="item.type == 'text'">
                                  <input
                                    type="text"
                                    class="lead"
                                    disabled="disabled"
                                  >
                                </div>
                                <div v-if="item.type == 'datepicker'">
                                  <input
                                    type="text"
                                    class="lead"
                                    disabled="disabled"
                                  >
                                </div>
                                <div v-if="item.type == 'textarea'">
                                  <textarea
                                    class="lead"
                                    disabled="disabled"
                                  />
                                </div>
                                <div v-if="item.type == 'dropdown'">
                                  <div
                                    class="select-container tall w-full"
                                  >
                                    <select disabled="disabled">
                                      <option
                                        v-for="option in item.options"
                                        :value="option"
                                      >
                                        {{ option }}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div v-if="item.type == 'workflow'">
                                  <div
                                    class="select-container tall w-full"
                                  >
                                    <select disabled="disabled">
                                      <option
                                        v-for="(option) in design.enabledWorkflows"
                                        :value="option.id"
                                      >
                                        {{ option.label }}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                      </transition-group>
                    </draggable>
                    <!-- End Draggable -->
                  </div>
                  <div class="mt-8 mb-5">
                  <h2 class="text-lg mb-6">
                    Add extra fields
                  </h2>
                  <div class="flex justify-between items-center">
                    <div>
                      <a
                        class="inline-block green-btn mr-3"
                        @click="addAdditionalFields('text')"
                      >
                        + Short answer
                      </a>
                      <a
                        class="inline-block green-btn mr-3 mb-3"
                        @click="addAdditionalFields('textarea')"
                      >
                        + Long answer
                      </a>
                      <a
                        class="inline-block green-btn mr-3"
                        @click="addAdditionalFields('datepicker')"
                      >
                        + Date
                      </a>
                      <a
                        class="inline-block green-btn mr-3"
                        @click="addAdditionalFields('dropdown')"
                      >
                        + Dropdown
                      </a>
                      <a
                        class="inline-block green-btn"
                        v-if="!this.listofFields.find((f) => f.type === 'workflow')"
                        @click="addAdditionalFields('workflow')"
                      >
                        + Workflow
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  v-show="!design.isRedirectOn"
                  class="pt-5 pb-8 content bg-white rounded px-8 mt-12 text-sm"
                >
                  <h1 class="text-xl mb-2">
                    Thank you message
                  </h1>
                  <textarea-autosize
                    v-model="formSetting.successMessage"
                    placeholder="Show a thank you message when a potential client filled in your form."
                    class="block mt-3 w-full"
                    :min-height="85"
                    :max-height="100"
                  />
                  <span
                    v-show="successMessageError"
                    class="error inline-block mt-2"
                  >Please enter a message to show when a potential client has submitted the form.</span>
                </div>
                <div class="mt-8 mb-10 text-right">
                  <a
                    v-show="!isSaving"
                    class="green-btn"
                    @click="save"
                  >
                    Save
                  </a>
                </div>
                <!-- End custom item -->
              </div>
              <!-- End create view -->
              <div
                v-if="view == 'preview'"
                class="text-right mt-8 mb-12"
              >
                <a
                  v-show="!isSaving"
                  class="green-btn"
                  @click="save"
                >
                  Save
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import filter from 'lodash.filter'
import draggable from 'vuedraggable'
import auth from '@/auth'
import { logException } from '@/helpers'
import VHelpTooltip from '../../components/VHelpTooltip/VHelpTooltip'
import { getWorkflows } from '../../api/Workflows'
import LoadingSelect from '../../components/LoadingSelect'

export default {
  components: {
    LoadingSelect,
    VHelpTooltip,
    draggable,
  },
	data(){
    	return {
        metaTitle: 'Loading',
        view: 'create',
        formId: null,
        multiDay: false,
        fakeDate: null,
        fakeStartDate: null,
        fakeEndDate: null,
        loading: false,
        mapping_options:[
        {
          label: 'Date',
          value: 'date',
          assignedTo: null,
        },{
          label: 'Location',
          value: 'location',
          assignedTo: null,
        },{
          label: 'Company',
          value: 'company',
          assignedTo: null,
        },{
          label: 'Phone',
          value: 'phone',
          assignedTo: null,
        },{
          label: 'Address',
          value: 'address',
          assignedTo: null,
        },{
          label: 'City',
          value: 'city',
          assignedTo: null,
        },{
          label: 'Postal code',
          value: 'postal_code',
          assignedTo: null,
        },{
          label: 'VAT',
          value: 'vat',
          assignedTo: null,
        }],
        formSetting: {
          name: '',
          successMessage: '',
          workflowId: null,
        },
    		listofFields: [{
          id: 1,
          isActive: true,
          label: 'Full name',
          type: 'text',
          mapping: 'name',
          is_mapping_fix: true,
          is_required: true,
          is_multi_days: false,
          options: [],
        },{
          id: 2,
          isActive: false,
          label: 'Email address',
          type: 'text',
          mapping: 'email',
          is_mapping_fix: true,
          is_required: true,
          is_multi_days: false,
          options: [],
        }],
        listofDefaultFields: [{
          id: 1,
          isActive: true,
          label: 'Full name',
          type: 'text',
          mapping: 'name',
          is_mapping_fix: true,
          is_required: true,
          is_multi_days: false,
          options: [],
        },{
          id: 2,
          isActive: false,
          label: 'Email address',
          type: 'text',
          mapping: 'email',
          is_mapping_fix: true,
          is_multi_days: false,
          is_required: true,
          options: [],
        }],
        listofForms: {},
    		success: false,
        embedLink: null,
        isSaving: false,
        isSaved: false,
        successMessageError: false,
        user: auth.user(),
        isShowVideoModal: false,
        tooltipContent: {
          content: `
            Store the input of this field to your contact sheet to the selected field. When selecting nothing you will still see it in your email and inside the project.
          `
        },
        displayColorPicker: false,
        design: {
          backgroundColor: { hex: '#F5F5F5'},
          isBackgroundTransparent: true,
          labelText: { hex: '#000000'},
          inputText: { hex: '#4A4A4A'},
          requiredText: { hex: '#D1D1D1'},
          inputBackground: { hex: '#FFFFFF'},
          borderColor: { hex: '#D1D1D1'},
          inputRadius: 4,
          buttonLabel: 'Submit',
          buttonColor: { hex: '#FFFFFF'},
          buttonBackground: { hex: '#0FCE83'},
          errorColor: { hex: '#FF0000'},
          legalBoxColor: { hex: '#FF0000'},
          isThankYouBackgroundTransparent: false,
          thankYouBackground: { hex: '#F3F6FF'},
          thankYouTextColor: { hex: '#000000'},
          thankYouText: 'Thank you for your request. We will contact you as soon as possible!',
          language: 'en',
          isRedirectOn: false,
          redirectLink: '',
          isLegalCheck: false,
          legalText: '',
          enabledWorkflows: [],
        },
        userMightUpdate: false,
        languageContent: {
          content: 'The default language for the project once a potential client completed the form.'
        },
        workflows: []
    	}
	},
  computed: {
    getTranslatedLabel() {
      return this.$translate('multiDay', this.design.language);
    },
    firstWorkflowId: {
      get() {
        return this.design.enabledWorkflows.length > 0 ? this.design.enabledWorkflows[0].id : null;
      },
      set(newValue) {
      }
    },
    backgroundPreview(){
      if(this.design.isBackgroundTransparent){
        return 'transparent'
      }
      return this.design.backgroundColor.hex
    },
    thankYouBackgroundPreview(){
      if(this.design.isThankYouBackgroundTransparent){
        return 'transparent'
      }
      return this.design.thankYouBackground.hex
    },

    activeLanguages() {
      const languages = filter(this.user.companySettings.locales, (language => language.isActive));

      return languages;
    }
  },
  async mounted(){
    await this.checkIntroVideo()
    this.loadWorkflows()
    if(this.$route.params.templateId){
      this.loading = true
      await this.getForm(this.$route.params.templateId)
      this.metaTitle = `Template Lead form: ${this.formSetting.name}`

      this.listofFields.forEach((item, index) => {
        if (typeof item.is_multi_days !== 'boolean') {
          this.$set(this.listofFields, index, {
            ...item,
            is_multi_days: !!item.is_multi_days,
          });
        }
      });

      if (!this.design.enabledWorkflows) {
        this.design.enabledWorkflows = [];
      }
      if (!this.design.legalBoxColor) {
        this.design.legalBoxColor = { hex: '#FF0000'};
      }
    } else {
      this.design.language = 'en'
      if(this.user.me.companySettings.defaultLanguage != ''){
        this.design.language = this.user.me.companySettings.defaultLanguage
      }
      this.metaTitle = 'New Template Lead form'
      this.design.enabledWorkflows = await this.workflows.map(
        (w) => {
          return {
            id: w.id,
            label: ""
          }
        }
      );
    }
  },
	methods: {
    handleCheckboxChange(index, event) {
      const newValue = event.target.checked;
      this.$set(this.listofFields, index, {
        ...this.listofFields[index],
        is_multi_days: newValue,
      });
    },
    async loadWorkflows() {
      const res = this.handleErrors(await getWorkflows())

      if (!res) return

      this.workflows = res.data.data.sort((a, b) => a.name > b.name ? 1 : -1)

      if (this.formSetting.workflowId === null) {
        this.formSetting.workflowId = this.workflows.find((w) => w.type === 'default').id
      }
    },
    async alertExit(){
      if(this.userMightUpdate){
        let link = '/templates'

        const canDelete = await this.$alert.confirm({
          title: 'Are you sure?',
          text: 'You might have unsaved changes, remember to save before closing.',
        })

        if (canDelete) {
          this.$router.push(link)
        }
      }
    },
    removeDropdownOption(index, optionIndex){
      this.listofFields[index].options.splice(optionIndex, 1)
    },
    addDropdownOption(index){
      this.listofFields[index].options.push('')
    },
    showColorPicker(ref){
      this.$forceUpdate()
      this.displayColorPicker = ref
      document.addEventListener('click', this.colorPick)
    },
    colorPick(e) {
      let el = this.$refs[this.displayColorPicker],
      target = e.target

      if(el){
        if(el !== target && !el.contains(target)) {
          this.hideColorPicker(e)
        }
      }
    },
    hideColorPicker(e){
      document.removeEventListener('click', e)
      this.displayColorPicker = false
    },
    onDrop (dropResult) {
      this.listofFields = applyDrag(this.listofFields, dropResult)
    },
    onCopy(){
      this.$toasted.global.general_success({
        message : 'You can now paste the embed code to your website.'
      })
    },
    changeMapping(targetId){
      let that = this
      this.listofFields.forEach(function(item) {
        that.mapping_options.forEach(function(map) {
          if(item.mapping == map.value){
            map.assignedTo = item.id
          }
        })
      })
    },
    async toggleWorkflow(workflowId, checked) {
      if (checked) {
        this.design.enabledWorkflows.push({
          id: workflowId,
          label: ""
        })
      } else {
        this.design.enabledWorkflows = this.design.enabledWorkflows.filter((w) => w.id !== workflowId)
      }
    },
    addAdditionalFields(type) {
      // console.log(this.listofFields.find((f) => f.type === type))

      // if this.listofFields already has a field of this type, don't add another one
      // if (this.listofFields.find((f) => f.type === type)) return

      // if (type == 'workflow') {
      //   this.design.enabledWorkflows = this.workflows.map((w) => w.id)
      // }

      this.listofFields.forEach(function(item) {
        item.isActive = false
      })
      this.listofFields.push({
          id: new Date().getTime(),
          isActive: true,
          label: '',
          type: type,
          mapping: 'nothing',
          options: [],
          is_mapping_fix: false,
          is_required: false,
          is_multi_days: false,
      })

      this.userMightUpdate = true
    },
    removeAdditionalFields(index){
      let field = this.listofFields[index]
      this.mapping_options.forEach(map => {
        if(map.assignedTo == field.id){
          map.assignedTo = null
        }
      })
      if (field.type == 'workflow') {
        this.design.enabledWorkflows = [];
      }
      this.listofFields.splice(index, 1)
      this.userMightUpdate = true
    },
    toggleActive(target){
      this.listofFields.forEach(function(item) {
        item.isActive = false
        if(item.id == target.id){
          item.isActive = true
        }
      })
    },
    handleDrop(data) {
      const { index, list, item } = data
      // change the id
      item.id = new Date().getTime()
      list.splice(index, 0, item)
    },
    handleMoved(item) {
      const { index, list } = item
      list.splice(index, 1)
    },
		save() {

      let that = this
      let error = false

      let urlPattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i') // fragment locator

      if(this.design.isRedirectOn){
        if(!urlPattern.test(this.design.redirectLink)){
          this.$toasted.global.general_error({
            message : 'Please enter a valid URL.'
          })
          error = true
          return false
        }
      }

      if(this.design.isLegalCheck){
        if(this.design.legalText.length == '' || this.design.legalText == '<p></p>'){
          this.$toasted.global.general_error({
            message : 'Please enter text for legal box.'
          })
          error = true
          return false
        }
      }

      this.successMessageError = false
      if(!this.design.isRedirectOn && this.formSetting.successMessage.length < 3){
        this.successMessageError = true
        error = true
        return false
      }

      this.listofFields.forEach(function(item) {
        if(item.label == ''){
          that.$toasted.global.general_error({
            message : 'Field label cannot be empty.'
          })
          error = true
        }
      })

      if (error) {
        return false
      }

      for (const [key, value] of Object.entries(this.design)) {
        if(value == ''){
          if (key == 'enabledWorkflows') {
            let workflowSet = this.listofFields.find((f) => f.type === 'workflow')

            if (workflowSet) {
              that.$toasted.global.general_error({
                message: 'Select a workflow.'
              })
              error = true
              return false
            }
            continue
          }
          if(key != 'isLegalCheck' && key != 'legalText' && key != 'isRedirectOn' && key != 'redirectLink' && key != 'isBackgroundTransparent' && key != 'isThankYouBackgroundTransparent'){
            that.$toasted.global.general_error({
              message : 'Design field cannot be empty.'
            })
            error = true
            return false
          }
        }
      }

      this.$validator.validate().then(result => {
        if(result && !error){
          this.doSave()
        }
      })
		},
    async doSave(){
        this.saving = true

        try {

          const postData = {
            companyId: this.user.company.id,
            formId: this.formId,
            name: this.formSetting.name,
            successMessage: this.formSetting.successMessage,
            fields: this.listofFields,
            design: this.design,
            workflowId: this.formSetting.workflowId
          }

          const { data } = await this.$api.get('leadform').updateOrCreate(postData)

          this.formId = data.leadForm.id
          this.embedLink = data.link
          this.isSaved = true
          this.saving = false
          this.userMightUpdate = false

          this.$toasted.global.general_success({
            message : 'Lead form template updated.'
          })

        } catch(e){
          logException(e)
        }
      },
    changeView(view){
      this.view = view
    },
    async getForm(id){

      this.loading = true

      try {
        const { data } = await this.$api.get('leadform').detail(id, 'id')
          this.formId = data.form.id
          this.formSetting.name = data.form.name
          this.formSetting.successMessage = data.form.success_message
          this.formSetting.workflowId = data.form.workflow_id

          if(data.form.setting){
            this.design = data.form.setting.options
          }

          if(this.design.language == '' || this.design.language == undefined){
            this.design.language = 'en'
            if(this.user.me.companySettings.defaultLanguage != ''){
              this.design.language = this.user.me.companySettings.defaultLanguage
            }
          }

          this.listofFields = []
          data.form.fields.forEach(field => {

            let options = []
            if(field.options){
              options = field.options
            }
            this.listofFields.push({
              id: field.id,
              isActive: false,
              label: field.label,
              type: field.type,
              mapping: field.mapping,
              options: options,
              is_mapping_fix: field.is_mapping_fix,
              is_required: field.is_required,
              is_multi_days: field.is_multi_days,
            })
          })

          this.embedLink = data.link
          this.loading = false
      } catch(e){
        logException(e)
      }
    },
    onClickBody(event){
      let that = this
      if(event.target.id == 'outer-wrapper'){
        that.listofFields.forEach(function(item) {
          if(item.label == ''){
            that.$toasted.global.general_error({
              message : 'Field label cannot be empty.'
            })
            return
          }

          item.isActive = false
        })
      }
    },
    async checkIntroVideo(){
      let showModal = false
      //let showModal = true
      // this.user.me.companySettings.watchIntroVideos.forEach(video => {
      //   if(video == 'leadform-edit'){
      //     showModal = false
      //   }
      // })

      if(showModal){
        this.isShowVideoModal = true
      }
    },
    showVideoModal(){
      this.isShowVideoModal = true
    },
    closeVideoModal(){
      this.isShowVideoModal = false
    },
	},
}
</script>
